import React, {useState} from 'react';
import './App.css';

function App() {

  const [firstInput, setFirstInput] = useState("");
  const [secondInput, setSecondInput] = useState("");
  const [operatorInput, setOperatorInput] = useState("");

  function mathInputOne(e) {
    setFirstInput(e.target.value)
  }

  function click9() {
    var textString = firstInput + "9"
    setFirstInput(textString)
  }

  function click8() {
    var textString = firstInput + "8"
    setFirstInput(textString)
  }

  function click7() {
    var textString = firstInput + "7"
    setFirstInput(textString)
  }

  function click6() {
    var textString = firstInput + "6"
    setFirstInput(textString)
  }

  function click5() {
    var textString = firstInput + "5"
    setFirstInput(textString)
  }

  function click4() {
    var textString = firstInput + "4"
    setFirstInput(textString)
  }

  function click3() {
    var textString = firstInput + "3"
    setFirstInput(textString)
  }

  function click2() {
    var textString = firstInput + "2"
    setFirstInput(textString)
  }

  function click1() {
    var textString = firstInput + "1"
    setFirstInput(textString)
  }

  function click0() {
    var textString = firstInput + "0"
    setFirstInput(textString)
  }

  function clickPlus() {
    if (secondInput === "") {
      setOperatorInput("+")
      setSecondInput(Number(firstInput))
      setFirstInput("")

    } else {

      setSecondInput(Number(secondInput) + Number(firstInput))
      setFirstInput("")
      setOperatorInput("+")

    }
  }

  function clickDivide() {

    if (secondInput !== "" && (firstInput === "" || firstInput === 0)) {
      setOperatorInput("/")
      setFirstInput("")
    }
    else if (secondInput === "") {
      setOperatorInput("/")
      setSecondInput(Number(firstInput))
      setFirstInput("")
    } 
    else if (firstInput === "" || firstInput === 0){
      setOperatorInput("/")
      setFirstInput("")
    } else {
        setOperatorInput("/")
        setSecondInput(Number(secondInput) / Number(firstInput))
    }

  }

  function clickMinus() {
    if (secondInput === "") {
      setOperatorInput("-")
      setSecondInput(Number(firstInput))
      setFirstInput("")

    } else {

      setSecondInput(Number(secondInput) - Number(firstInput))
      setFirstInput("")
      setOperatorInput("-")

    }
  }

  function clickMultiply() {

    if (secondInput !== "" && (firstInput === "" || firstInput === 0)) {
      setOperatorInput("*")
      setFirstInput("")
    } else if (secondInput === "") {
      setOperatorInput("*")
      setSecondInput(Number(firstInput))
      setFirstInput("")

    } else {

      setSecondInput(Number(secondInput) * Number(firstInput))
      setFirstInput("")
      setOperatorInput("*")

    }

  }

  function clickEquals() {

    if (secondInput === "" && firstInput !== "") {
      setSecondInput(Number(firstInput))

    } 
    else if (secondInput !== "" && (firstInput === "" || firstInput === "")){
      setFirstInput("")
    }
    else {

      if (operatorInput === "+") {
        setSecondInput(Number(secondInput) + Number(firstInput))
      }
      else if  (operatorInput === "-") {
        setSecondInput(Number(secondInput) - Number(firstInput))
  
      } else if  (operatorInput === "*") {
        setSecondInput(Number(secondInput) * Number(firstInput))
  
      } else if  (operatorInput === "/") {
        if (Number(firstInput) === 0) {
          setSecondInput(0)
        }
        setSecondInput(Number(secondInput) / Number(firstInput))
  
      }

    }

    setFirstInput("")
  }

  function backClick() {
    var textString = firstInput.slice(0,(firstInput.length - 1))
    setFirstInput(textString)
  }

  function clearEntry() {
    setFirstInput("")
  }

  function clearAll() {
    setFirstInput("")
    setSecondInput("")
    setOperatorInput("")
  }

  return (

    <div>
    <div class="card">
      <div class="card-body">
        <div id="display" class="row">
          <div class="input-group mb-3">
            <input 
              type="text" 
              class="form-control" 
              aria-label="Sizing example input" 
              aria-describedby="inputGroup-sizing-default"
              value={secondInput}
              disabled
            />

          </div>

          <div class="input-group mb-3">
            <input 
              type="number" 
              pattern="[0-9]" 
              class="form-control" 
              aria-label="Sizing example input" 
              aria-describedby="inputGroup-sizing-default"
              maxlength="17"
              value={firstInput}
              onChange={mathInputOne}
              disabled
            />  
          </div>
    
        </div>
          
        <div class="container text-center">
          <div class="row">
              <button type="button" class="numbers col btn btn-success btn-lg" onClick={clickDivide}>/</button>
              <button type="button" class="numbers col btn btn-success btn-lg" onClick={clickMultiply}>x</button>
              <button type="button" class="numbers col btn btn-success btn-lg" onClick={clickPlus}>+</button>
              <button type="button" class="numbers col btn btn-success btn-lg" onClick={clickMinus}>-</button>
          </div>
          <div class="row">
              <button type="button" class="numbers col btn btn-primary btn-lg" onClick={click7}>7</button>
              <button type="button" class="numbers col btn btn-primary btn-lg" onClick={click8}>8</button>
              <button type="button" class="numbers col btn btn-primary btn-lg" onClick={click9}>9</button>
              <button type="button" class="numbers col btn btn-danger btn-lg" onClick={clearEntry}>CE</button>
          </div>
          <div class="row">
              <button type="button" class="numbers col btn btn-primary btn-lg" onClick={click4}>4</button>
              <button type="button" class="numbers col btn btn-primary btn-lg" onClick={click5}>5</button>
              <button type="button" class="numbers col btn btn-primary btn-lg" onClick={click6}>6</button>
              <button type="button" class="numbers col btn btn-danger btn-lg" onClick={clearAll}>AC</button>
          </div>
          <div class="row">
              <button type="button" class="numbers col btn btn-primary btn-lg" onClick={click1}>1</button>
              <button type="button" class="numbers col btn btn-primary btn-lg" onClick={click2}>2</button>
              <button type="button" class="numbers col btn btn-primary btn-lg" onClick={click3}>3</button>
              <button type="button" class="numbers col btn btn-danger btn-lg" onClick={backClick}>
              <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
              </svg>
              </button>
          </div>
          <div class="row">
              <button type="button" class="numbers col btn btn-secondary btn-lg"></button>
              <button type="button" class="numbers col btn btn-primary btn-lg" onClick={click0}>0</button>
              <button type="button" class="numbers col btn btn-secondary btn-lg"></button>
              <button type="button" class="numbers col btn btn-success btn-lg" onClick={clickEquals}>=</button>
          </div>
        </div>
      </div>
      </div>
      <footer>
          <a class="nav-link link-light" href="https://www.techbyderek.com">
            <img 
              id="de-logo" 
              src="D LOGO BLACK - 360 - NO BG.png" 
              alt="Logo for Derek Evans" 
              title="Animated traffic light application by Derek Evans - Click to return to main page." 
              height="130em" 
              width="130em"
            />
          </a>
      </footer>
    </div>
  );
}

export default App;
